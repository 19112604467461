import { jarallax } from "jarallax";
import $ from "jquery";
import Odometer from "odometer";
import AOS from "aos";
export default {
  init() {
    // JavaScript to be fired on all pages
	AOS.init();
	setTimeout(() => {
		AOS.refresh();
	}, 1000);
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    $(".outlets-slider").slick({
      autoplay: true,
      slidesToShow: 9,
      centerMode: true,
      arrows: false,
      autoplaySpeed: 0,
      speed: 3000,
      cssEase: "linear",
      variableWidth: true,
    });

    document.addEventListener("DOMContentLoaded", function () {
      var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

      if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              let lazyImage = entry.target;
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.srcset = lazyImage.dataset.srcset;
              lazyImage.classList.remove("lazy");
              lazyImageObserver.unobserve(lazyImage);
            }
          });
        });

        lazyImages.forEach(function (lazyImage) {
          lazyImageObserver.observe(lazyImage);
        });
      } else {
        // Possibly fall back to a more compatible method here
      }
    });

    $("img.svg").each(function () {
      var $img = $(this);
      var imgID = $img.attr("id");
      var imgClass = $img.attr("class");
      var imgURL = $img.attr("src");

      $.get(
        imgURL,
        function (data) {
          // Get the SVG tag, ignore the rest
          var $svg = $(data).find("svg");

          // Add replaced image's ID to the new SVG
          if (typeof imgID !== "undefined") {
            $svg = $svg.attr("id", imgID);
          }
          // Add replaced image's classes to the new SVG
          if (typeof imgClass !== "undefined") {
            $svg = $svg.attr("class", imgClass + " replaced-svg");
          }

          // Remove any invalid XML tags as per http://validator.w3.org
          $svg = $svg.removeAttr("xmlns:a");

          // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
          if (
            !$svg.attr("viewBox") &&
            $svg.attr("height") &&
            $svg.attr("width")
          ) {
            $svg.attr(
              "viewBox",
              "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
            );
          }

          // Replace image with new SVG
          $img.replaceWith($svg);
        },
        "xml"
      );
    });

    $(".core-value span").hover(function () {
      var id = $(this).attr("id").match(/\d+$/)[0];

      $(".core-value span").removeClass("active");
      $(this).addClass("active");

      $(".value-text span").removeClass("active");
      $("#value-text-" + id).addClass("active");
    });

    document
      .querySelector("#mobiletoggle")
      .addEventListener("click", function () {
        this.classList.toggle("active");
      });

    $("li.menu-item-has-children a").on("click touch", function () {
      if (!$(this).hasClass("active")) {
        $("li.menu-item-has-children .active").removeClass("active");
        $(this).addClass("active");
        $(this).next().addClass("active");
      } else {
        $(this).removeClass("active");
        $(this).next().removeClass("active");
      }
    });

    $(document).on("click", (event) => {
      let openMenu = document.querySelector(
        "li.menu-item-has-children a.active"
      );
      if (openMenu && event.target !== openMenu) {
        $(openMenu).removeClass("active");
        $(openMenu).next().removeClass("active");
      }
    });

    $("#mobiletoggle, #mobileclose").click(function () {
      $("#nav.mobile").toggleClass("on");
      return false;
    });

    $(".page-template-archive .hero h2").html(
      $(".page-template-archive .hero h2")
        .text()
        .replace(/[^:.]*$/, '<span class="after">$&</span>')
    );

    $(".page-template-archive .blog-list-page .card .post-title").each(
      function () {
        $(this).html(
          $(this)
            .text()
            .replace(/[^:.]*$/, '<span class="after">$&</span>')
        );
      }
    );

    if ($(window).width() < 768) {
      jarallax(document.querySelectorAll(".jarallax-industry-studies"), {
        imgPosition: "0 100px",
        imgSize: "contain",
        speed: 0.7,
      });
    }

    jarallax(document.querySelectorAll(".jarallax-industry-studies"), {
      imgPosition: "48vw -15px",
      imgSize: "scale-down",
    });

    if ($(window).width() < 768) {
      jarallax(document.querySelectorAll(".jarallax-webinars"), {
        imgPosition: "0 50px",
        imgSize: "contain",
        speed: 0.7,
      });
    }

    jarallax(document.querySelectorAll(".jarallax-webinars"), {
      imgPosition: "48vw -15px",
      imgSize: "scale-down",
    });

    if ($(window).width() < 768) {
      jarallax(document.querySelectorAll(".jarallax-success-stories"), {
        imgPosition: "0 60px",
        imgSize: "scale-down",
        speed: 0.7,
      });
    }

    jarallax(document.querySelectorAll(".jarallax-success-stories"), {
      imgPosition: "52vw -15px",
      imgSize: "scale-down",
    });

    jarallax(document.querySelectorAll(".jarallax"), {});

    // CTV Hero Slider for blocks
    $(".slide-title-container-inner").slick({
      autoplay: true,
      infinite: true,
      lazyLoad: "ondemand",
      slidesToScroll: 1,
      slidesToShow: 1,
      pauseOnHover: true,
      arrows: false,
      dots: true,
    });

    // JS for blocks
    $(".pixa_scrolling_logo_row_slider").slick({
      speed: 3000,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      slidesToShow: 9,
      slidesToScroll: 0.4,
      pauseOnHover: true,
      centerMode: true,
      arrows: false,
      variableWidth: true,
    });

    // JS for Block Name: Image Slider
    $(".pixa_img_slider").slick({
      lazyLoad: "ondemand",
      slidesToScroll: 1,
      slidesToShow: 4,
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    try {
      // Odometer related code for pixa_banner_stat_cards
      var pixaBannerStatCardOdometer = document.querySelector(
        ".pixa_banner_stat_cards .odometer"
      );
      new Odometer({
        el: pixaBannerStatCardOdometer,
        format: "dddd",
      });
      $(document).scroll(function () {
        $(".pixa_banner_stat_cards .odometer").each(function () {
          if (isScrolledIntoViewCommon($(this))) {
            $(this).html("" + $(this).data("stat"));
          }
        });
      });
    } catch (error) {
      console.log(error);
    }

    try {
      // Odometer related code for pixa_use_cases_list
      var pixaUseCaseListOdometer = document.querySelector(
        ".pixa_use_cases_list .odometer"
      );
      new Odometer({
        el: pixaUseCaseListOdometer,
        format: "dd,ddd",
      });
      $(document).scroll(function () {
        $(".pixa_use_cases_list .odometer").each(function () {
          if (isScrolledIntoViewCommon($(this))) {
            $(this).html("" + $(this).data("stat"));
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  },
};

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function isScrolledIntoViewCommon(element) {
  var pageTop = $(window).scrollTop();
  var pageBottom = pageTop + $(window).height();
  var elementTop = $(element).offset().top;
  var elementBottom = elementTop + $(element).height();

  return elementTop <= pageBottom && elementBottom >= pageTop;
}
