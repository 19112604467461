import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import careersCulture from './routes/culture';
import ourSolution from './routes/our-solution';
import news from './routes/news';
import team from './routes/team';
import postTypeArchiveNews from './routes/archive-news';
import postTypeArchiveTeam from './routes/archive-team';

function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}

jQuery(window).scroll(function () {
    var top_offset = jQuery(window).scrollTop();
    if (top_offset == 0) {
        jQuery('#main-header').addClass('top');
    } else {
        jQuery('#main-header').removeClass('top');
    }
})

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** About Us page, note the change from about-us to aboutUs. */
  careersCulture,
  ourSolution,
  news,
  team,
  postTypeArchiveNews,
  postTypeArchiveTeam
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
