import {
    jarallax
} from 'jarallax';
import $ from 'jquery';


export default {
  init() {
    // JavaScript to be fired on all pages

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    jarallax(document.querySelectorAll('.jarallax'), {});

    $('.slider').slick({
      lazyLoad: 'ondemand',
      slidesToScroll: 1,
      slidesToShow: 4,
      arrows: true,
      responsive: [
        { 
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
        }
       ]
    });
     
  },
};