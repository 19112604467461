import AOS from 'aos';
import $ from 'jquery';
import 'bootstrap';

export default {
  init() {
    // JavaScript to be fired on all pages

    $('h3').each(function(){
      var me = $(this);
      me.html( me.text().replace(/(^\w+)/,'<span>$1</span>') );
    });

  },
  finalize() {
    // vanilla JS
    // init with element
     
  },
};
