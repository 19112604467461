import {
    jarallax
} from 'jarallax';
import $ from 'jquery';
import 'slick-carousel';

function fadeTypewriter(element) {
  $('#typewriterText').fadeOut(function() {
    $('.Typewriter__wrapper').html('');
    $('#typewriterText').show();
  });
}

export default {
  init() {

	let $hero_slider = $('.hero-alternative-layout__slides');

	$hero_slider.on('init', (slick) => {
		$('body.home').addClass('body-alternative-hero-layout');
		$('.page-template').addClass('page-template-alternative-hero-layout');

		let $current_slide_heading_one = $hero_slider.find('.slick-current').find('.hero-alternative-layout__heading-one');
		$current_slide_heading_one.css('opacity', '1'); 
		$current_slide_heading_one.css('left', '0');

		let $current_slide_heading_two = $hero_slider.find('.slick-current').find('.hero-alternative-layout__heading-two');
		$current_slide_heading_two.css('opacity', '1');
		$current_slide_heading_two.css('left', '0');

		let $current_slide_cta = $hero_slider.find('.slick-current').find('.hero-alternative-layout__cta');
		$current_slide_cta.css('opacity', '1');
		$current_slide_cta.css('bottom', '0');

		let $current_slide_image = $hero_slider.find('.slick-current').find('.hero-alternative-layout__right').find('img');
		$current_slide_image.css('opacity', '1');

		let $current_slide_video = $hero_slider.find('.slick-current').find('.hero-alternative-layout__right').find('video');
		$current_slide_video.css('opacity', '1');
	});

	$hero_slider.on('afterChange', (slick, currentSlide) => {
		let $not_current_slide_heading_one = $hero_slider.find(':not(.slick-current)').find('.hero-alternative-layout__heading-one');
		$not_current_slide_heading_one.css('opacity', '0');

		if (window.matchMedia("(min-width: 992px)").matches) {
			$not_current_slide_heading_one.css('left', '-35px');
		}

		let $current_slide_heading_one = $hero_slider.find('.slick-current').find('.hero-alternative-layout__heading-one');
		$current_slide_heading_one.css('opacity', '1');
		$current_slide_heading_one.css('left', '0');

		let $not_current_slide_heading_two = $hero_slider.find(':not(.slick-current)').find('.hero-alternative-layout__heading-two');
		$not_current_slide_heading_two.css('opacity', '0');

		if (window.matchMedia("(min-width: 992px)").matches) {
			$not_current_slide_heading_two.css('left', '-35px');
		}

		let $current_slide_heading_two = $hero_slider.find('.slick-current').find('.hero-alternative-layout__heading-two');
		$current_slide_heading_two.css('opacity', '1');
		$current_slide_heading_two.css('left', '0');

		let $not_current_slide_cta = $hero_slider.find(':not(.slick-current)').find('.hero-alternative-layout__cta');
		$not_current_slide_cta.css('opacity', '0');
		$not_current_slide_cta.css('bottom', '-20px');

		let $current_slide_cta = $hero_slider.find('.slick-current').find('.hero-alternative-layout__cta');
		$current_slide_cta.css('opacity', '1');
		$current_slide_cta.css('bottom', '0');

		let $not_current_slide_image = $hero_slider.find(':not(.slick-current)').find('.hero-alternative-layout__right').find('img');
		$not_current_slide_image.css('opacity', '0');

		let $not_current_slide_video = $hero_slider.find(':not(.slick-current)').find('.hero-alternative-layout__right').find('video');
		$not_current_slide_video.css('opacity', '0');

		let $current_slide_image = $hero_slider.find('.slick-current').find('.hero-alternative-layout__right').find('img');
		$current_slide_image.css('opacity', '1');

		let $current_slide_video = $hero_slider.find('.slick-current').find('.hero-alternative-layout__right').find('video');
		$current_slide_video.css('opacity', '1');
	});

	$hero_slider.slick({
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 1,
		swipe: true,
		useCSS: false,
		useTransform: false,
		pauseOnHover: false
  	});

    $('.trusted-slider').slick({
	    autoplay: true,
	    slidesToShow: 9,
	    centerMode: true,
	    arrows: false,
	    autoplaySpeed: 0,
	    speed: 3000,
	    cssEase: 'linear',
      variableWidth: true
  	});

  	$("#facebookGif").hide();
  	$("#instagramGif").hide();

  	$("#facebookLogo").hover(function() {
  		$("#facebookGif").show();
  		$("#instagramGif").hide();
  		$("#youtubeGif").hide();

  		$('.social-media .logo').removeClass('active');
  		$(this).addClass('active');
  	});

  	$("#instagramLogo").hover(function() {
  		$("#instagramGif").show();
  		$("#youtubeGif").hide();
  		$("#facebookGif").hide();

  		$('.social-media .logo').removeClass('active');
  		$(this).addClass('active');
  	});

  	$("#youtubeLogo").hover(function() {
  		$("#youtubeGif").show();
  		$("#facebookGif").hide();
  		$("#instagramGif").hide();

  		$('.social-media .logo').removeClass('active');
  		$(this).addClass('active');
  	});

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    jarallax(document.querySelectorAll('.jarallax'), {});

    $('.what-we-do .content').height($('.content__container__list__item').height());

    /*
     * Replace all SVG images with inline SVG
     */
    $('img.svg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

  },
};