import AOS from 'aos';

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('archive news');

  },
  finalize() {
    // vanilla JS
    // init with element

    jQuery('#media-coverage-tab').click(function(){
      jQuery('#myTabContent').addClass('only-media-coverage').removeClass('only-press-releases');
    });

    jQuery('#all-tab').click(function(){
      jQuery('#myTabContent').removeClass('only-media-coverage').removeClass('only-press-releases');
    });

    jQuery('#press-releases-tab').click(function(){
      jQuery('#myTabContent').removeClass('only-media-coverage').addClass('only-press-releases');
    });


  },
};
